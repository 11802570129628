import React, { useState, useRef } from 'react';

const VideoRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [videoBlob, setVideoBlob] = useState(null);
    const videoRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const chunks = useRef([]);

    const startRecording = async () => {
        setIsRecording(true);

        // Get video stream from webcam
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        videoRef.current.srcObject = stream;

        mediaRecorderRef.current = new MediaRecorder(stream);

        mediaRecorderRef.current.ondataavailable = (event) => {
            if (event.data.size > 0) {
                chunks.current.push(event.data);
            }
        };

        mediaRecorderRef.current.onstop = () => {
            const blob = new Blob(chunks.current, { type: 'video/mp4' });
            setVideoBlob(blob);
            chunks.current = [];
        };

        mediaRecorderRef.current.start();
    };

    const stopRecording = () => {
        setIsRecording(false);
        mediaRecorderRef.current.stop();
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
    };

    const handleUpload = async () => {
        if (videoBlob) {
            const formData = new FormData();
            formData.append('video', videoBlob, 'video.mp4');

            await fetch('/upload-video', {
                method: 'POST',
                body: formData,
            });
        }
    };

    return (
        <div>
            <video ref={videoRef} autoPlay style={{ width: '100%' }}></video>
            <div>
                {isRecording ? (
                    <button onClick={stopRecording}>Stop Recording</button>
                ) : (
                    <button onClick={startRecording}>Start Recording</button>
                )}
            </div>
            {videoBlob && (
                <div>
                    <video src={URL.createObjectURL(videoBlob)} controls style={{ width: '100%' }}></video>
                    <button onClick={handleUpload}>Upload Video</button>
                </div>
            )}
        </div>
    );
};

export default VideoRecorder;

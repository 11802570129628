import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Box,
  TextField,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  MenuItem,
  Fab,
  Backdrop,
} from "@mui/material";
import Sidebar from "../components/Sidebar";
import MatterHeader from "./components/MatterHeader";
import MatterLinks from "./components/MatterLinks";
import { Worker, Viewer, Page } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { zoomPlugin } from "@react-pdf-viewer/zoom";
import { thumbnailPlugin } from "@react-pdf-viewer/thumbnail";
import { toolbarPlugin, ToolbarSlot } from "@react-pdf-viewer/toolbar";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import "@react-pdf-viewer/thumbnail/lib/styles/index.css";
import { ChromePicker } from "react-color";
import PaletteIcon from "@mui/icons-material/Palette";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import DeleteIcon from "@mui/icons-material/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { styled } from "@mui/system";
import { highlightPlugin } from "@react-pdf-viewer/highlight";
import "@react-pdf-viewer/highlight/lib/styles/index.css";
import CustomSnackbar from "../components/CustomSnackbar";
import { Navigate, useParams } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle"; // Import the icon
import RestartAltIcon from "@mui/icons-material/RestartAlt"; // Import the reset icon
import * as XLSX from "xlsx-js-style";
import UploadFileIcon from "@mui/icons-material/UploadFile"; // Import the file upload icon
import JSZip from "jszip";

// Plugins
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { get, post } from "../helpers/apiService"; // Import the fetch functions
import LoadingIcon from "../components/LoadingIcon";
import SaveIcon from "@mui/icons-material/Save";
import CircularProgress from "@mui/material/CircularProgress";

const CustomTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
    "& input": {
      height: "40px",
    },
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
    {
      WebkitAppearance: "none",
      margin: 0,
    },
});

const MatterDeopsitionsPdf = () => {
  const fileInputRef = useRef(null);
  const viewerRef = useRef(null);
  const { id, deposition_id } = useParams();

  const [pagePdfNumber, setPagePdfNumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const [pageLoading, setPageLoading] = useState(false);
  const [pdfScriptLoading, setPdfScriptLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [saveLoading, setSaveLoading] = useState(false);

  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfName, setPdfName] = useState("");
  const [pdfVersion, setPdfVersion] = useState(0);
  const [pdfDirectory, setPdfDirectory] = useState("");

  const [pdfSearchType, setPdfSearchType] = useState("automatic"); // automatic, pdf page no,  manual
  const [pdfSearchPageNo, setPdfSearchPageNo] = useState("");
  const [isDisablePdfSearch, setDisablePdfSearch] = useState(false);

  const [isMultipleUpload, setMultipleUpload] = useState(false); // upload pdf value
  const [defaultFileData, setDefaultData] = useState({
    path: "",
    directory: "",
  });

  const PdfSearch = [
    { value: "automatic", label: "Automatic" },
    { value: "pdfPageNo", label: "PDF Page No" },
    { value: "manual", label: "Manual" },
  ];

  //popup for selecting pdf color
  const [open, setOpen] = useState(false);
  const handleOpenColorModel = (parentIndex) => {
    setOpen(true);
    setOpenConfirmationModelData(parentIndex);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenConfirmationModelData("");
  };

  useEffect(() => {
    fetchDepositionPdf(); //fetch data from api
  }, []);

  const fetchDepositionPdf = () => {
    if (pageLoading) {
      return false;
    }

    setPageLoading(true);

    get(`matter/deposition/get/${deposition_id}`)
      .then((data) => {
        setPageLoading(false); // enable loading indicator
        if (data.status) {
          // check if already save then show all value be prefilled
          var tmpFilePath = data.matter.file_path;
          var tmpFileDirectory = data.matter.file_directory;

          setDefaultData({ path: tmpFilePath, directory: tmpFileDirectory }); // set default path

          if (data.matter && data.matter.deposition_pdf) {
            var selected_pdf_data = data.matter.deposition_pdf;
            setPdfSearchType(selected_pdf_data.pdf_search_type);
            if (selected_pdf_data.pdf_search_pageno) {
              setPdfSearchPageNo(selected_pdf_data.pdf_search_pageno);
            }
            setDisablePdfSearch(true);

            setDesignations(selected_pdf_data.highlighted_colors);

            tmpFilePath = selected_pdf_data.file_path;
            tmpFileDirectory = selected_pdf_data.file_directory;
          }

          fetch(
            `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${tmpFilePath}`
          )
            .then((response) => {
              if (response.ok) {
                return response.blob(); // Convert the response to a Blob
              } else {
                showSnackbar("Network response was not ok.", "error");
              }
            })
            .then((blob) => {
              const url = URL.createObjectURL(blob); // Create a URL for the Blob
              setPdfUrl(url);
              setCurrentPage(0);

              // set pdf name
              var pdfNameTemp = destructuePdfName(tmpFilePath);
              setPdfName(pdfNameTemp);

              /// set pdf directory
              setPdfDirectory(tmpFileDirectory);
            })
            .catch((error) => {
              console.error("Error:", error);
              showSnackbar("Failed to fetch PDF.", "error");
            });
        } else {
          Navigate("/dashboard");
          showSnackbar(data.message, "error");
        }
      })
      .catch((error) => {
        setPageLoading(false); // enable loading indicator
        if (error.name === "AbortError") {
          console.log("Fetch aborted");
        } else {
          const errorMessage = error.message || "An unknown error occurred";
          showSnackbar(errorMessage, "error");
        }
      });
  };

  const destructuePdfName = (filePath) => {
    // Example file path
    // const filePath = "uploads/17_1725252799.pdf";
    if (!filePath) {
      return "";
    }

    // Extract the filename with extension from the path
    const filenameWithExtension = filePath.split("/").pop();

    // Extract the filename without extension
    const filenameWithoutExtension = filenameWithExtension
      .split(".")
      .slice(0, -1)
      .join(".");

    return filenameWithoutExtension;
  };

  const showSnackbar = (msg, type = "success") => {
    setSnackbarMessage(msg);
    setSnackbarSeverity(type);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const colorPickerCodeFirst = [
    "#f15d5f",
    "#fad4d3",
    "#a35f61",
    "#c8a29b",
    "#93747b",
    "#fec1cf",
    "#a8333e",
    "#fab0a8",
    "#2dbb83",
    "#c3eddf",
    "#4f754c",
    "#99a67f",
    "#394148",
    "#879da1",
    "#224369",
    "#aebbc8",
    "#4b62e3",
    "#cfd5f8",
    "#342d7e",
    "#b1b2f4",
    "#539ecd",
    "#c3e5fb",
    "#eeaa41",
    "#fbeacb",
    "#644432",
    "#fed2a8",
    "#b55728",
    "#d3c192",
    "#262939",
    "#646464",
    "#757a89",
    "#afb2bd",
  ];

  const colorCodeMiddle1 = [
    ["#ae181a", "#cd282b", "#f23930", "#e06768", "#ffc4cb"],
    ["#7f0f44", "#ba1552", "#e41d56", "#ed5787", "#f5b1c7"],
    ["#432683", "#6d2d97", "#9130a6", "#b25cc0", "#deb6df"],
    ["#2d298a", "#492f9e", "#5b35ad", "#8a69c9", "#cabbe3"],
    ["#1a2272", "#2a3693", "#2a3693", "#6e7ac4", "#bdc0e6"],
    ["#0f3e97", "#196bcc", "#198bf1", "#57abf5", "#b4d5f5"],
    ["#004d8f", "#017ccb", "#019df4", "#46bbf5", "#a8e0fa"],
  ];

  const colorCodeMiddle2 = [
    ["#005457", "#018c9c", "#00b2cd", "#44c8de", "#a7e6ef"],
    ["#024335", "#006e5d", "#018b7b", "#45ac9f", "#a9dad6"],
    ["#1b422e", "#2e8334", "#43a546", "#74bf75", "#c1e1c5"],
    ["#2b5c18", "#5c9431", "#7eb943", "#a7cd75", "#d5e9c0"],
    ["#766b19", "#a3aa2d", "#c5d636", "#d8e168", "#ecf1b9"],
    ["#f1741a", "#fab72b", "#fee73a", "#fdee6e", "#fcf6ba"],
    ["#fc6413", "#fe9522", "#feb92e", "#ffcf47", "#fee9a9"],
  ];

  const colorCodeMiddle3 = [
    ["#e24809", "#f17116", "#fe8d22", "#ffad42", "#ffdaa5"],
    ["#b72f0c", "#e23f1c", "#fe4c22", "#fe7d59", "#fdc4b4"],
    ["#352320", "#523734", "#6b4a3d", "#977b71", "#cfc4bf"],
    ["#202b2f", "#3f4e5b", "#54707e", "#8399a2", "#c9d2d4"],
    ["#000000", "#484848", "#8b8b8a", "#d3d3d3", "#fefefe"],
  ];

  // main designation value
  const [designations, setDesignations] = useState([
    { label: "", color: "#1976d2", options: [] },
  ]);
  const [openConfirmationModel, setOpenConfirmationModel] = useState(false);
  const [openConfirmationModelData, setOpenConfirmationModelData] =
    useState("");
  const [openColorModelData, setOpenColorModelData] = useState({
    parentIndex: "",
  });

  // Function to open the confirmation dialog
  const handleOpenDialog = (parentIndex) => {
    setOpenConfirmationModel(true);
    setOpenConfirmationModelData(parentIndex);
  };

  // Function to close the confirmation dialog
  const handleCloseDialog = () => {
    setOpenConfirmationModel(false);
    setOpenConfirmationModelData("");
  };

  // add new designation row
  const addDesignations = () => {
    // Update state correctly using the setter function
    setDesignations((prevOptions) => [
      ...prevOptions,
      { label: "", color: "#1976d2", options: [] },
    ]);
  };

  // set data value in label & color
  const setData = (key, val, index) => {
    setDesignations((prevOptions) => {
      const updatedOptions = [...prevOptions];
      updatedOptions[index] = { ...updatedOptions[index], [key]: val };
      return updatedOptions;
    });
  };

  // add designations options
  const addOptions = (parentIndex) => {
    setDesignations((prevOptions) => {
      const updatedDesignations = [...prevOptions];
      updatedDesignations[parentIndex].options = [
        ...updatedDesignations[parentIndex].options,
        {
          page: "",
          fromLine: "",
          toLine: "",
          color: "#1976d2",
          isAdded: false,
          linkPage: "",
        },
      ];
      return updatedDesignations;
    });
  };

  // set option value option array "designations"
  const setOptionValue = (value, key, optionIndex, parentIndex) => {
    setDesignations((prevOptions) => {
      const updatedDesignations = [...prevOptions];

      // Ensure the optionIndex is within bounds
      if (optionIndex < updatedDesignations[parentIndex].options.length) {
        // Update the page value of the specific option
        updatedDesignations[parentIndex].options[optionIndex] = {
          ...updatedDesignations[parentIndex].options[optionIndex],
          [key]: value,
        };
      }
      return updatedDesignations;
    });
  };

  // Initialize the plugins
  const zoomPluginInstance = zoomPlugin();
  const thumbnailPluginInstance = thumbnailPlugin();
  const { Thumbnails } = thumbnailPluginInstance;
  const toolbarPluginInstance = toolbarPlugin();

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // Function to check if any option has isAdded set to true
  const hasAddedOptions = (index) => {
    if(designations[index] && designations[index].options)
      return designations[index].options.some((option) => option.isAdded);

    return false;
  };

  // delete deleteDesignation data
  const deleteDesignation = () => {
    setOpenConfirmationModel(false);

    // check if any option have added pdf highlights p[oints ] then remove first before deleting
    if (hasAddedOptions(openConfirmationModelData)) {
      showSnackbar(
        "Please delete highlighted pdf points before deleting this.",
        "error"
      );
      return false;
    }

    setDesignations((prevOptions) => {
      // Ensure the parentIndex is within bounds
      if (
        openConfirmationModelData >= 0 &&
        openConfirmationModelData < prevOptions.length
      ) {
        return prevOptions.filter(
          (_, index) => index !== openConfirmationModelData
        );
      }
      return prevOptions; // Return the unchanged state if index is out of bounds
    });
  };

  // delete designaiton option row
  const deleteDesignationValue = (index, parentIndex) => {
    const rowData = getOptionValue(parentIndex, index);

    if (!rowData) {
      return false;
    }

    // data is added in pdf then delete this from api
    if (rowData.isAdded) {
      applyColorPdf(rowData, index, parentIndex, "delete"); // call api when there is no errors
    }

    //console.log(rowData);
    //console.log(designations);
    //return false;

    setDesignations((prevOptions) => {
      // Create a copy of the previous state
      const updatedDesignations = [...prevOptions];

      // Ensure the designationIndex and optionIndex are within bounds
      if (
        parentIndex < updatedDesignations.length &&
        index < updatedDesignations[parentIndex].options.length
      ) {
        // Remove the option at optionIndex
        updatedDesignations[parentIndex].options = updatedDesignations[
          parentIndex
        ].options.filter((_, idx) => idx !== index);
      }

      // console.log(updatedDesignations);

      // Return the updated state
      return updatedDesignations;
    });
  };

  // get option value
  const getOptionValue = (parentIndex, optionIndex) => {
    // Check if indices are within bounds
    if (
      parentIndex < designations.length &&
      optionIndex < designations[parentIndex].options.length
    ) {
      return designations[parentIndex].options[optionIndex];
    }
    return null; // Or some default value if the indices are out of bounds
  };

  // add color to designation
  const addColorDesignationValue = (index, parentIndex) => {
    const rowData = getOptionValue(parentIndex, index);

    if (!rowData) {
      return false;
    }

    //var parentRowData = designations[parentIndex];

    var checkError = false;
    if (!rowData.page || rowData.fromLine === "0") {
      showSnackbar("Please enter a page number", "error");
      checkError = true;
    } else if (!rowData.fromLine || rowData.fromLine === "0") {
      showSnackbar("Please enter a valid from line number", "error");
      checkError = true;
    } else if (!rowData.toLine || rowData.toLine === "0") {
      showSnackbar("Please enter a valid to line number", "error");
      checkError = true;
    } else if (parseInt(rowData.toLine) < parseInt(rowData.fromLine)) {
      showSnackbar(
        "To line must be greater than or equal to from line",
        "error"
      );
      checkError = true;
    }
    // else if (parseInt(rowData.page) > 50) {
    //   showSnackbar("Page no cannot be greater then 50", "error");
    //   checkError = true;
    // }

    if (!checkError) {
      var checkPdfType = handleCheckStartingPageNo(); // check all pdf assumptions
      if (checkPdfType) {
        setOptionValue(true, "isAdded", index, parentIndex);
        applyColorPdf(rowData, index, parentIndex); // call api when there is no errors
      }
    }
  };

  const applyColorPdf = (selectedRowData, index, parentIndex, type = "add") => {
    const numbersHighlight = [];
    var pageNo = parseInt(selectedRowData.page);

    if (pdfSearchType === "manual") {
      pageNo = Number(pageNo) - 1;
    } else if (pdfSearchType === "pdfPageNo") {
      pageNo = Number(pageNo) + Number(pdfSearchPageNo) - 2;
    }

    var parentRowData = designations[parentIndex];

    // Generate numbers from 3 to 7
    for (
      let i = parseInt(selectedRowData.fromLine);
      i <= parseInt(selectedRowData.toLine);
      i++
    ) {
      numbersHighlight.push(i.toString());
    }

    var pdfFileVersion = `${pdfName}.pdf`;
    if (pdfVersion) {
      var pdfFileVersion = `${pdfName}-${pdfVersion}.pdf`;
    }

    // for the first time we need laravel uploaded dirctory url only
    if (pdfDirectory) {
      var pdfFileVersion = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfDirectory}/${pdfFileVersion}`;
    }

    var outputPath = `${pdfName}-${pdfVersion + 1}.pdf`;

    setPdfScriptLoading(true); // enable loading indicator

    fetch(`${process.env.REACT_APP_PYTHON_LOCAL_URL}highlight`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pdf_path: pdfFileVersion,
        output_path: outputPath,
        numbers_to_highlight: numbersHighlight,
        // pages_to_highlight: [pageNo],
        pages_to_highlight: pageNo,
        multi_import_array: [],
        pdf_search_pageNo: pdfSearchPageNo,
        pdf_search_type: pdfSearchType,
        color: parentRowData.color,
        opacity: type == "add" ? 1 : 0,
        pdf_download: pdfDirectory ? "true" : "false",
        is_multi_import: false,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          setPdfScriptLoading(false); // disable loading indicator
          showSnackbar("Network response was not ok", "error");
        }
        return response.json();
      })
      .then((result) => {
        if (result.status == "true") {
          setPdfVersion((prevVersion) => prevVersion + 1); // increase pdf version count
          setPdfUrl(result.file_url);
          setCurrentPage(0);

          if (type == "add") {
            setOptionValue(
              result.highlight_page,
              "linkPage",
              index,
              parentIndex
            ); // set highlight page no
          }

          setPdfDirectory(""); // set blank so we can reset this value
        } else {
          setOptionValue(false, "isAdded", index, parentIndex);
        }

        showSnackbar(
          result.message,
          result.status == "true" ? "success" : "error"
        );

        setPdfScriptLoading(false); // disable loading indicator
      })
      .catch((error) => {
        setPdfScriptLoading(false); // disable loading indicator
        console.log(error, "error");
      });
  };

  const handleCheckStartingPageNo = () => {
    if (pdfSearchType === "pdfPageNo" && !pdfSearchPageNo) {
      showSnackbar("Please enter starting page no.", "error");
      return false;
    }

    setDisablePdfSearch(true);

    return true;
  };

  const handleResetStartingPageNo = () => {
    const hasAnyOptionAdded = designations.some(
      (designation) =>
        designation.options &&
        designation.options.some((option) => option.isAdded)
    );

    if (hasAnyOptionAdded) {
      showSnackbar(
        "Please delete highlighted pdf points before reset.",
        "error"
      );
    } else {
      setDisablePdfSearch(false);
    }
    //
  };

  // save data in pdf database
  const handleSavePdfData = (isFileUrl) => {
    //check is there any uploaded data are abalulrnlae
    if (isMultipleUpload) {
      showSnackbar(
        "Please review & confirm imported values before saving",
        "error"
      );
      return false;
    }
    // check user have done some changes before saving
    if (pdfUrl.startsWith("blob:")) {
      // showSnackbar("Please make some pdf highlights before saving.", "error");
      // return false;
      var pdfFileUrl = "";
    } else {
      var pdfFileUrl = pdfUrl;
    }

    if(isFileUrl){
      pdfFileUrl = isFileUrl;
    }

    // check if loading already enable
    if (saveLoading) {
      return false;
    }

    const requestData = {
      matter_id: id,
      deposition_id: deposition_id,
      file_url: pdfFileUrl,
      pdf_search_type: pdfSearchType,
      pdf_search_pageno: pdfSearchPageNo,
      highlighted_colors: Object.values(designations),
    };

    setSaveLoading(true); // enable loading indicator
    post("matter/deposition/pdf", requestData)
      .then((data) => {
        setSaveLoading(false); // enable loading indicator
        if (data.status) {
        }

        showSnackbar(data.message, data.status ? "success" : "error");
      })
      .catch((error) => {
        setSaveLoading(false); // enable loading indicato
        console.log(error, "error");
        showSnackbar("Bad network error", "error");
      });
  };

  // upload xlsx file
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check if the uploaded file is an .xlsx file
    if (file && file.name.endsWith(".xlsx")) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Get the first sheet's name and data
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert worksheet to a 2D array format
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        // Extract the designations from the first row
        const designations = sheetData[1] || []; // Assuming the designations are in the second row (index 1)

        // Extract options for each designation
        const optionsData = designations.map((designation, index) => {
          // Collect options from subsequent rows for the current designation
          const options = sheetData
            .slice(2)
            .map((row) => row[index] || "")
            .filter((option) => option !== "");
          return {
            designation,
            options,
          };
        });

        // Create the final object structure
        const result = {
          sheetName: firstSheetName,
          designations: optionsData,
        };

        // Log the result to verify the output
        //console.log("Extracted Data:", result);
        // Pass the result to upload function or handle it as needed
        if (result.designations.length > 0) {
          uploadXlsxFileData(result.designations);
        } else {
          showSnackbar("No designations or options found for import.", "error");
        }
      };

      // Read the file
      reader.readAsArrayBuffer(file);
    } else {
      showSnackbar("Please upload a valid .xlsx file.", "error");
    }

    // Reset the input value so that the same file can be uploaded again
    event.target.value = null;
  };

  // upload xlsx data
  const uploadXlsxFileData = (allSheetData) => {
    // Regular expression to match the pattern "<page>:<fromLine>-<page>:<toLine>"
    const regex = /(\d+):(\d+)-\d+:(\d+)/;

    var allData = [];
    allSheetData.forEach((items, parentIndex) => {
      var allDataOptions = [];
      //var colorCode = "#1976d2";
      // Get a random color from colorPickerCodeFirst
      var colorCode =
        colorPickerCodeFirst[
          Math.floor(Math.random() * colorPickerCodeFirst.length)
        ];

      // check all options value
      items.options.forEach((itemOption, index) => {
        // Use `match` to get the groups from the input string
        const matches = itemOption.match(regex);

        if (matches) {
          allDataOptions.push({
            page: matches[1],
            fromLine: matches[2],
            toLine: matches[3],
            color: "#1976d2",
            isAdded: false,
            linkPage: "",
          });
        }

        //colorCode = itemOption["Color"];
      });

      // arrange data array
      allData.push({
        label: items.designation,
        color: colorCode,
        options: allDataOptions,
        isNewUpload: true,
      });
    });

    // console.log(allData);
    // console.log(designations);

    var existingDesignationCount = designations.length;
    var uploadDesignationCount = allData.length;

    var allDataCount = existingDesignationCount + uploadDesignationCount;
    //console.log(allDataCount);

    // user can notr add more then 10 desuignation
    // if (allDataCount > 10) {
    //   showSnackbar("You can’t add more than 10 designations.", "error");
    //   return false;
    // }

    //setDesignations([...designations, allData]);
    setDesignations((prevOptions) => [...prevOptions, ...allData]);

    setMultipleUpload(true); // enable upload button after successfully import all xlsx file
    return false;
    setPdfUrl("");
    setPdfVersion(0); // set pdf version 0 it can be start from 0

    setMultipleUpload(true); // enable upload button after successfully import all xlsx file

    fetch(
      `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${defaultFileData.path}`
    )
      .then((response) => {
        if (response.ok) {
          return response.blob(); // Convert the response to a Blob
        } else {
          showSnackbar("Network response was not ok.", "error");
        }
      })
      .then((blob) => {
        //console.log('dasd');
        const url = URL.createObjectURL(blob); // Create a URL for the Blob
        //console.log(url);
        setPdfUrl(url);
        setCurrentPage(0);

        // set pdf name
        var pdfNameTemp = destructuePdfName(defaultFileData.path);
        //console.log(pdfNameTemp);
        setPdfName(pdfNameTemp);

        /// set pdf directory
        setPdfDirectory(defaultFileData.directory);
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Failed to fetch PDF.", "error");
      });
  };

  /// one click import all desinations
  const importAllDesinations = () => {
    var checkPdfType = handleCheckStartingPageNo(); // check all pdf assumptions
    if (checkPdfType) {
      const numbersHighlight = [];

      var pdfFileVersion = `${pdfName}.pdf`;
      if (pdfVersion) {
        var pdfFileVersion = `${pdfName}-${pdfVersion}.pdf`;
      }

      // for the first time we need laravel uploaded dirctory url only
      if (pdfDirectory) {
        var pdfFileVersion = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfDirectory}/${pdfFileVersion}`;
      }

      var outputPath = `${pdfName}-${pdfVersion + 1}.pdf`;

      setPdfScriptLoading(true); // disable loading indicator

      fetch(`${process.env.REACT_APP_PYTHON_LOCAL_URL}highlight`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pdf_path: pdfFileVersion,
          output_path: outputPath,
          numbers_to_highlight: numbersHighlight,
          pages_to_highlight: "",
          pdf_search_type: pdfSearchType,
          multi_import_array: designations,
          pdf_search_pageNo: pdfSearchPageNo,
          color: "#1976d2",
          opacity: 1,
          pdf_download: pdfDirectory ? "true" : "false",
          is_multi_import: true,
          pdf_version: pdfVersion ? pdfVersion + 1 : 1,
          pdf_name: pdfName,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            setPdfScriptLoading(false); // disable loading indicator
            showSnackbar("Network response was not ok", "error");
          }
          return response.json();
        })
        .then((result) => {
          if (result.status == "true") {
            setPdfVersion(parseInt(result.pdf_version) - 1); // increase pdf version count
            setPdfUrl(result.file_url);
            setCurrentPage(0);

            setPdfDirectory(""); // set blank so we can reset this value
            setMultipleUpload(false); // disabnle multiple import button

            setDesignations(result.updated_designation_array);
          } else {
            //setOptionValue(false, "isAdded", index, parentIndex);
          }

          showSnackbar(
            result.message,
            result.status == "true" ? "success" : "error"
          );

          setPdfScriptLoading(false); // disable loading indicator
        })
        .catch((error) => {
          setPdfScriptLoading(false); // disable loading indicator
          console.log(error, "error");
        });
    }
  };

  // reapply highlighted colors
  const reapplyColor = (isSaved) => {

    console.log(openConfirmationModelData);

    var designationData = designations[openConfirmationModelData].options

    // Filter the options where isAdded is true
    var filteredData = designationData.filter(option => option.isAdded === true);

    if(filteredData.length > 0){

      handleClose();  // close color picker

      var pdfFileVersion = `${pdfName}.pdf`;
      if (pdfVersion) {
        var pdfFileVersion = `${pdfName}-${pdfVersion}.pdf`;
      }

      // for the first time we need laravel uploaded dirctory url only
      if (pdfDirectory) {
        var pdfFileVersion = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfDirectory}/${pdfFileVersion}`;
      }

      var outputPath = `${pdfName}-${pdfVersion + 1}.pdf`;

      setPdfScriptLoading(true); // disable loading indicator

      fetch(`${process.env.REACT_APP_PYTHON_LOCAL_URL}rehighlight`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          pdf_path: pdfFileVersion,
          output_path: outputPath,
          pdf_search_type: pdfSearchType,
          multi_import_array: filteredData,
          pdf_search_pageNo: pdfSearchPageNo,
          color: designations[openConfirmationModelData].color,
          pdf_download: pdfDirectory ? "true" : "false",
          pdf_version: pdfVersion ? pdfVersion + 1 : 1,
          pdf_name: pdfName,
        }),
      })
        .then((response) => {
          if (!response.ok) {
            setPdfScriptLoading(false); // disable loading indicator
            showSnackbar("Network response was not ok", "error");
          }
          return response.json();
        })
        .then((result) => {
          if (result.status == "true") {

            setPdfVersion(parseInt(result.pdf_version) - 1); // increase pdf version count
            setPdfUrl(result.file_url);
            setCurrentPage(0);

            setPdfDirectory(""); // set blank so we can reset this value

            // if user click on apply & save then data will be save automatically
            if(isSaved){
              setTimeout(() => {
                handleSavePdfData(result.file_url);
              }, 2000); // 2 seconds delay
            }


          } else {
            //setOptionValue(false, "isAdded", index, parentIndex);
          }

          showSnackbar(
            result.message,
            result.status == "true" ? "success" : "error"
          );

          setPdfScriptLoading(false); // disable loading indicator
        })
        .catch((error) => {
          setPdfScriptLoading(false); // disable loading indicator
          console.log(error, "error");
        });
    }  
  }

  // logic is too much triky
  const goToPage = (pageNumber) => {
    if (!pageNumber) {
      return false;
    }
    // console.log(pageNumber);
    // console.log(currentPage);
    //if (pageNumber) setCurrentPage(pageNumber); // Adjust for zero-based index

    //if (pagePdfNumber !== currentPage) {

    //console.log('dasd');
    setCurrentPage(0);

    setTimeout(() => {
      //if (currentPage) {
      setCurrentPage(pageNumber);
      //console.log(currentPage);
      //}
      // setCurrentPage(pageNumber - 1); // Adjust for zero-based index
    }, 20);
    // }
  };

  const handleDownload = async () => {
    if (designations.length > 0) {
      var pdfFileName = getFileNameWithoutExtension(
        `${pdfName ? pdfName : "depositions"}.pdf`
      );

      const zip = new JSZip();
      const xlsxFileName = await convertPdfToXlsx(designations, zip);

      // Delay for 3 seconds (3000 milliseconds)
      await delay(2000);

      await handleDownloadPdfLatestVersion(designations, zip, xlsxFileName);

      // Generate the ZIP file and trigger download
      zip.generateAsync({ type: "blob" }).then((content) => {
        const zipFileName = `${pdfName ? pdfName : "depositions"}.zip`;
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = zipFileName;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  };

  const convertPdfToXlsx = async (rowData, zip) => {
    var pdfFileName = getFileNameWithoutExtension(
      `${pdfName ? pdfName : "deposition"}.pdf`
    );
    var pdfVersion = "";
    var data = rowData;

    if (data.length === 0) {
      showSnackbar("No record found for downloading XLSX file.", "error");
      return false;
    }

    const workbook = XLSX.utils.book_new();
    const worksheetData = [];

    // Add the PDF name in the first row
    worksheetData.push([`Deposition of ${pdfFileName}`]);

    // Collect all designations into the second row
    const designationsRow = [];
    data.forEach((item) => {
      designationsRow.push(`${item.label ? item.label : "Designations"}`);
    });
    worksheetData.push(designationsRow);

    // Collect all options and add them in the subsequent rows
    let maxOptions = 0;
    const optionsColumns = data.map((item) => {
      const options = item.options
        .filter((opt) => opt.isAdded)
        .map((opt) => `${opt.page}:${opt.fromLine}-${opt.page}:${opt.toLine}`);

      maxOptions = Math.max(maxOptions, options.length);
      return options;
    });

    // Add rows for each option under the respective designation columns
    for (let i = 0; i < maxOptions; i++) {
      const row = [];
      optionsColumns.forEach((options) => {
        row.push(options[i] || "");
      });
      worksheetData.push(row);
    }

    // Create the worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // Merge the first row cells to span the width of the designations
    const mergeCell = {
      s: { r: 0, c: 0 },
      e: { r: 0, c: designationsRow.length - 1 },
    };
    if (!worksheet["!merges"]) worksheet["!merges"] = [];
    worksheet["!merges"].push(mergeCell);

    // Apply basic styling to the merged cell and the header row (designations)
    worksheet["A1"].s = {
      fill: { fgColor: { rgb: "d8d8d8" } }, // Gray background
      alignment: { horizontal: "center", vertical: "center" },
      font: { color: { rgb: "000000" }, sz: 12, bold: true }, // Black text
      border: {
        top: { style: "thin", color: { rgb: "000000" } },
        bottom: { style: "thin", color: { rgb: "000000" } },
        left: { style: "thin", color: { rgb: "000000" } },
        right: { style: "thin", color: { rgb: "000000" } },
      },
    };

    designationsRow.forEach((_, index) => {
      const cellRef = XLSX.utils.encode_cell({ r: 1, c: index });
      worksheet[cellRef].s = {
        fill: { fgColor: { rgb: "d8d8d8" } }, // Gray background
        alignment: { horizontal: "center", vertical: "center" },
        font: { color: { rgb: "000000" }, sz: 12, bold: true }, // Black text
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
    });

    // Calculate column widths
    const columnWidths = [];
    worksheetData[1].forEach((_, colIndex) => {
      const maxLength = Math.max(
        ...worksheetData.map((row) => row[colIndex]?.toString().length || 0)
      );
      columnWidths.push({ wch: maxLength + 10 }); // Add extra space
    });

    //console.log(columnWidths)

    // Apply column widths to the worksheet
    worksheet["!cols"] = columnWidths;

    // Add the worksheet to the workbook
    // Add the worksheet to the workbook, truncating the pdfFileName to 31 characters
    const truncatedFileName = pdfFileName.substring(0, 31); // Truncate to 31 characters

    XLSX.utils.book_append_sheet(workbook, worksheet, truncatedFileName);

    const xlsxFileName = `${
      pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName
    }.xlsx`;

    // Write workbook to array buffer
    const xlsxArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert array buffer to Blob
    const xlsxBlob = new Blob([xlsxArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Add the XLSX file to the ZIP
    zip.file(xlsxFileName, xlsxBlob);

    return xlsxFileName;
  };

  const handleDownloadPdfLatestVersion = async (rowData, zip, xlsxFileName) => {
    console.log(pdfUrl);
    if (pdfUrl.startsWith("blob:")) {
      try {
        // Fetch the blob data from the blob URL
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        // Convert the Blob to ArrayBuffer, as jszip needs binary data
        const arrayBuffer = await blob.arrayBuffer();

        // Add the PDF file to the ZIP (name it accordingly, here using 'abc.pdf')
        zip.file(`${pdfName ? pdfName : "depositions"}.pdf`, arrayBuffer);
      } catch (error) {
        console.log("Error downloading PDF blob:", error);
      }
    } else {
      //var pdfUrl = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfFile}`;
      try {
        const response = await fetch(pdfUrl);
        const blob = await response.blob();

        // Add the PDF file to the ZIP
        zip.file(`${pdfName ? pdfName : "depositions"}.pdf`, blob);
      } catch (error) {
        console.error("Error downloading the file:", error);
      }
      console.log("no blob pdf url found");
    }
    // var pdfFile = rowData.file_path;
    // var pdfFileName = rowData.file_name;
    // var pdfVersion = "";
    // if (rowData.deposition_pdf) {
    //   pdfFile = rowData.deposition_pdf.file_path;
    //   pdfVersion = rowData.deposition_pdf.file_version;
    // }

    // var pdfUrl = `${process.env.REACT_APP_BACKEND_URL}serve-pdf.php?file=${pdfFile}`;
    // try {
    //   const response = await fetch(pdfUrl);
    //   const blob = await response.blob();

    //   const pdfFileNameWithVersion = `${
    //     pdfVersion ? "v" + pdfVersion + "_" + pdfFileName : pdfFileName
    //   }`;

    //   // Add the PDF file to the ZIP
    //   zip.file(pdfFileNameWithVersion, blob);
    // } catch (error) {
    //   console.error("Error downloading the file:", error);
    // }
  };

  const getFileNameWithoutExtension = (filename) => {
    return filename.split(".").slice(0, -1).join(".");
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  return (
    <Box sx={{ display: "flex" }}>
      <Sidebar />
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 0, width: "100%" }}
      >
        <div>
          <MatterHeader title="" />
          <MatterLinks />
          {pageLoading ? (
            <LoadingIcon />
          ) : (
            <div style={{ display: "flex" }}>
              {/* <Box
              sx={{ width: "15%", overflowY: "auto", height: "100vh" }}
              className="scrollable"
            >
              <Thumbnails onThumbnailClick={handleThumbnailClick} />
            </Box> */}
              <Box sx={{ flexGrow: 1, height: "100vh" }} className="scrollable">
                {pdfUrl ? (
                  <Worker
                    workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}
                  >
                    {/* <toolbarPluginInstance.Toolbar /> */}

                    <Viewer
                      key={currentPage} // This forces the Viewer to re-render when currentPage changes
                      // ref={viewerRef}
                      fileUrl={pdfUrl}
                      // Attach ref to Viewer
                      plugins={[
                        // highlightPluginInstance,
                        //zoomPluginInstance,
                        // thumbnailPluginInstance,
                        //toolbarPluginInstance,

                        defaultLayoutPluginInstance,
                      ]}
                      //initialPage={pageNumber}
                      initialPage={currentPage} // Set the current page
                      // pageIndex={pageNumber - 1}
                      defaultScale={1} // Set the scale to 50%
                      //onPageChange={handlePageChange} // Attach the page change handler
                    />
                  </Worker>
                ) : (
                  <LoadingIcon />
                )}
              </Box>

              <Box
                sx={{
                  width: "30%",
                  overflowY: "auto",
                  height: "100vh",
                  bgcolor: "background.paper",
                  p: 2,
                }}
              >
                <Button
                  onClick={handleDownload}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                  size="small" // Set the size to small
                >
                  Download
                </Button>
                <br />

                {isMultipleUpload && (
                  <Button
                    onClick={importAllDesinations}
                    variant="contained"
                    color="secondary"
                    sx={{ mt: 2 }}
                    size="small" // Set the size to small
                  >
                    Review & Confirm Imported Values
                  </Button>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    mt: "15px",
                  }}
                >
                  <TextField
                    select
                    fullWidth
                    label="Page Read"
                    size="small"
                    disabled={isDisablePdfSearch} // Use this prop to disable the TextField
                    value={pdfSearchType}
                    onChange={(e) => setPdfSearchType(e.target.value)}
                    variant="outlined"
                  >
                    {PdfSearch.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>

                  {pdfSearchType === "pdfPageNo" && (
                    <>
                      <TextField
                        label="Starting Page"
                        variant="outlined"
                        value={pdfSearchPageNo}
                        disabled={isDisablePdfSearch} // Use this prop to disable the TextField
                        onChange={(e) => setPdfSearchPageNo(e.target.value)}
                        size="small"
                      />
                    </>
                  )}

                  <Tooltip title={isDisablePdfSearch ? "Reset" : "Save"}>
                    <IconButton
                      color="success"
                      onClick={
                        isDisablePdfSearch
                          ? handleResetStartingPageNo
                          : handleCheckStartingPageNo
                      }
                    >
                      {isDisablePdfSearch ? (
                        <RestartAltIcon />
                      ) : (
                        <CheckCircleIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
                {/* designaitons */}
                {designations.map((parentRow, parentIndex) => (
                  <>
                    <Box
                      key={parentIndex}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1,
                        mt: "15px",
                      }}
                    >
                      <TextField
                        label="Designation label"
                        variant="outlined"
                        value={parentRow.label}
                        onChange={(e) =>
                          setData("label", e.target.value, parentIndex)
                        }
                        // sx={{
                        //   width: "88%",
                        // }}
                        size="small"
                      />
                      <TextField
                        // label="Color"
                        variant="outlined"
                        value={parentRow.color}
                        onChange={(e) =>
                          setData("color", e.target.value, parentIndex)
                        }
                        size="small"
                        sx={{
                          width: "150px",
                        }}
                        InputProps={{
                          sx: {
                            "& input": {
                              backgroundColor: parentRow.color, // Set your desired background color here
                              borderColor: parentRow.color,
                            },
                          },
                        }}
                      />
                      <IconButton
                        sx={{
                          bgcolor: "#000000dd",
                          width: 24,
                          height: 24,
                          borderRadius: "50%",
                          p: 0,
                          "&:hover": {
                            bgcolor: "#000000dd",
                          },
                        }}
                        onClick={() => handleOpenColorModel(parentIndex)}
                      >
                        <PaletteIcon sx={{ color: "#fff" }} />
                      </IconButton>
                    </Box>

                    <TableContainer
                      component={Paper}
                      sx={{
                        maxHeight: "300px",
                        pt: "2px",
                        border:
                          parentRow.options.length > 0 &&
                          isMultipleUpload &&
                          parentRow.isNewUpload
                            ? "1px solid red"
                            : "none",
                      }}
                    >
                      <Table stickyHeader aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                py: 0.5,
                                px: 0,
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              #Page
                            </TableCell>
                            <TableCell
                              sx={{
                                py: 0.5,
                                px: 0,
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              From line
                            </TableCell>
                            <TableCell
                              sx={{
                                py: 0.5,
                                px: 0,
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              To line
                            </TableCell>
                            <TableCell
                              sx={{
                                py: 0.5,
                                px: 0,
                                backgroundColor: "#f1f1f1",
                              }}
                            >
                              {" "}
                              <Tooltip title="Add new page">
                                <IconButton
                                  onClick={() => addOptions(parentIndex)}
                                  color="primary"
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete designation">
                                <IconButton
                                  onClick={() => handleOpenDialog(parentIndex)}
                                  color="error"
                                >
                                  <DeleteIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </TableCell>
                            {/* <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#fff",width:'40px' }}
                          > */}
                            {/* <IconButton
                              onClick={() => handleOpenDialog(parentIndex)}
                              color="error"
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton> */}
                            {/* </TableCell> */}
                            {/* <TableCell
                            sx={{ py: 0.5, px: 0, backgroundColor: "#fff",width:'40px' }}
                          ></TableCell> */}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {parentRow.options.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{
                                  py: 0,
                                  px: 0,
                                  cursor: row.linkPage ? "pointer" : "default",
                                }}
                                onClick={() => goToPage(row.linkPage)}
                              >
                                {row.isAdded ? (
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row.page}
                                  </span>
                                ) : (
                                  <CustomTextField
                                    variant="outlined"
                                    size="small"
                                    value={row.page}
                                    onChange={(e) =>
                                      setOptionValue(
                                        e.target.value,
                                        "page",
                                        index,
                                        parentIndex
                                      )
                                    }
                                    type="number"
                                    InputProps={{
                                      style: {
                                        border: "none",
                                        height: "30px",
                                        padding: 0,
                                      },
                                      notched: false,
                                      readOnly: row.isAdded, // Make the field read-only
                                    }}
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                    sx={{
                                      width: "55px",
                                      padding: 0,
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  py: 0.5,
                                  px: 0,
                                  cursor: row.linkPage ? "pointer" : "default",
                                }}
                                onClick={() => goToPage(row.linkPage)}
                              >
                                {row.isAdded ? (
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row.fromLine}
                                  </span>
                                ) : (
                                  <CustomTextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={row.fromLine}
                                    onChange={(e) =>
                                      setOptionValue(
                                        e.target.value,
                                        "fromLine",
                                        index,
                                        parentIndex
                                      )
                                    }
                                    InputProps={{
                                      style: { border: "none", height: "30px" },
                                      notched: false,
                                      readOnly: row.isAdded, // Make the field read-only
                                    }}
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                    sx={{
                                      width: "55px",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  py: 0.5,
                                  px: 0,
                                  cursor: row.linkPage ? "pointer" : "default",
                                }}
                                onClick={() => goToPage(row.linkPage)}
                              >
                                {row.isAdded ? (
                                  <span
                                    style={{
                                      paddingLeft: "10px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {row.toLine}
                                  </span>
                                ) : (
                                  <CustomTextField
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    value={row.toLine}
                                    onChange={(e) =>
                                      setOptionValue(
                                        e.target.value,
                                        "toLine",
                                        index,
                                        parentIndex
                                      )
                                    }
                                    InputProps={{
                                      style: { border: "none", height: "30px" },
                                      notched: false,
                                      readOnly: row.isAdded, // Make the field read-only
                                    }}
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                    sx={{
                                      width: "55px",
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell sx={{ py: 0.5, px: 0 }}>
                                {/* <Tooltip title="Save"> */}
                                <IconButton
                                  onClick={() =>
                                    addColorDesignationValue(index, parentIndex)
                                  }
                                  sx={
                                    {
                                      // padding: "8px 2px 8px 4px",
                                    }
                                  }
                                  color="success"
                                  disabled={!row.isAdded ? false : true}
                                >
                                  <CheckIcon fontSize="small" />
                                </IconButton>
                                {/* </Tooltip> */}

                                <Tooltip title="Delete">
                                  <IconButton
                                    onClick={() =>
                                      deleteDesignationValue(index, parentIndex)
                                    }
                                    color="error"
                                    sx={{
                                      padding: "8px 2px 8px 8px",
                                    }}
                                  >
                                    <DeleteIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </>
                ))}
                {/* {designations.length < 10 ? ( */}
                <Button
                  onClick={addDesignations}
                  variant="contained"
                  color="secondary"
                  sx={{ mt: 2 }}
                  size="small" // Set the size to small
                >
                  Add new Designation
                </Button>
                {/* // ) : (
                //   <div
                //     style={{
                //       marginBottom: "40PX",
                //     }}
                //   ></div>
                // )} */}
                <Fab
                  color="primary"
                  aria-label="add"
                  onClick={()=>handleSavePdfData("")}
                  style={{
                    position: "fixed",
                    bottom: "1px",
                    right: "16px",
                  }}
                >
                  {saveLoading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    <span>Save</span>
                  )}
                </Fab>
                {!isMultipleUpload && (
                  <>
                    <Fab
                      color="primary"
                      aria-label="add"
                      onClick={() => {
                        fileInputRef.current.click();
                      }}
                      style={{
                        position: "fixed",
                        bottom: "1px",
                        right: "80px",
                      }}
                    >
                      <UploadFileIcon />
                    </Fab>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      //multiple
                      onChange={handleFileUpload}
                      accept=".xlsx"
                    />
                  </>
                )}
              </Box>
            </div>
          )}
        </div>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        severity={snackbarSeverity}
        onClose={handleCloseSnackbar}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            //width: 400,
            bgcolor: "background.paper",
            //width: "70vw", // Set width to 50% of viewport width
            // height: "72vh",
            // border: "2px solid #000",
            boxShadow: 24,
            display: "flex",
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "grid",
              width: "20%",
              gridTemplateColumns: "repeat(2, 90px)", // Two columns with fixed width
              gridAutoRows: "26px", // Set height of each row
              gap: "0px", // Gap between rows and columns
              width: "fit-content", // Adjust the container width to fit the content
            }}
          >
            {colorPickerCodeFirst.map((row, index) => (
              <Box
                key={index}
                onClick={() =>
                  // setOptionValue(
                  //   row,
                  //   "color",
                  //   openColorModelData.index,
                  //   openColorModelData.parentIndex
                  // )
                  setData("color", row, openConfirmationModelData)
                }
                sx={{
                  width: "80px",
                  height: "24px",
                  backgroundColor: row,
                  border: `1px solid ${row}`, // Optional border for better visibility
                  cursor: "pointer", // Show cursor pointer on hover
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Center the icon
                }}
              >
                {designations[openConfirmationModelData] &&
                  designations[openConfirmationModelData].color === row && (
                    <CheckIcon sx={{ color: "black" }} />
                  )}
                {/* Adjust color if needed */}
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              // display: "grid",
              // gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
              // gridAutoRows: "24px", // Set height of each row
              // gap: "7px", // Gap between rows and columns
              width: "fit-content", // Adjust the container width to fit the content
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
                //gridAutoRows: "24px", // Set height of each row
                gap: "7px", // Gap between rows and columns
                width: "fit-content", // Adjust the container width to fit the content
              }}
            >
              {/* Loop through colorPickerCode2 and colorPickerCode3 */}
              {colorCodeMiddle1.map((rows, index) => (
                <Box
                  key={index}
                  sx={
                    {
                      // display: "flow",
                    }
                  }
                >
                  {[...rows].map((row, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        // setOptionValue(
                        //   row,
                        //   "color",
                        //   openColorModelData.index,
                        //   openColorModelData.parentIndex
                        // )
                        setData("color", row, openConfirmationModelData)
                      }
                      sx={{
                        width: "60px",
                        height: "24px",
                        backgroundColor: row,
                        border: `1px solid ${row}`, // Optional border for better visibility
                        cursor: "pointer", // Show cursor pointer on hover
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the icon
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {designations[openConfirmationModelData] &&
                        designations[openConfirmationModelData].color ===
                          row && <CheckIcon sx={{ color: "black" }} />}
                      {/* Adjust color if needed */}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
                // gridAutoRows: "24px", // Set height of each row
                gap: "7px", // Gap between rows and columns
                width: "fit-content", // Adjust the container width to fit the content
                mt: "10px",
              }}
            >
              {/* Loop through colorPickerCode2 and colorPickerCode3 */}
              {colorCodeMiddle2.map((rows, index) => (
                <Box
                  key={index}
                  sx={
                    {
                      // display: "flow",
                    }
                  }
                >
                  {[...rows].map((row, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        // setOptionValue(
                        //   row,
                        //   "color",
                        //   openColorModelData.index,
                        //   openColorModelData.parentIndex
                        // )

                        setData("color", row, openConfirmationModelData)
                      }
                      sx={{
                        width: "60px",
                        height: "24px",
                        backgroundColor: row,
                        border: `1px solid ${row}`, // Optional border for better visibility
                        cursor: "pointer", // Show cursor pointer on hover
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the icon
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {designations[openConfirmationModelData] &&
                        designations[openConfirmationModelData].color ===
                          row && <CheckIcon sx={{ color: "black" }} />}
                      {/* Adjust color if needed */}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(7, 60px)", // 7 columns with fixed width
                // gridAutoRows: "24px", // Set height of each row
                gap: "7px", // Gap between rows and columns
                width: "fit-content", // Adjust the container width to fit the content
                mt: "10px",
              }}
            >
              {/* Loop through colorPickerCode2 and colorPickerCode3 */}
              {colorCodeMiddle3.map((rows, index) => (
                <Box
                  key={index}
                  sx={
                    {
                      // display: "flow",
                    }
                  }
                >
                  {[...rows].map((row, index) => (
                    <Box
                      key={index}
                      onClick={() =>
                        // setOptionValue(
                        //   row,
                        //   "color",
                        //   openColorModelData.index,
                        //   openColorModelData.parentIndex
                        // )
                        setData("color", row, openConfirmationModelData)
                      }
                      sx={{
                        width: "60px",
                        height: "24px",
                        backgroundColor: row,
                        border: `1px solid ${row}`, // Optional border for better visibility
                        cursor: "pointer", // Show cursor pointer on hover
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center", // Center the icon
                        marginBottom: "2px",
                      }}
                    >
                      {" "}
                      {designations[openConfirmationModelData] &&
                        designations[openConfirmationModelData].color ===
                          row && <CheckIcon sx={{ color: "black" }} />}
                      {/* Adjust color if needed */}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            sx={{
              width: "40%",
              width: "fit-content", // Adjust the container width to fit the content
              paddingLeft: "20px",
            }}
          >
            <Box
              sx={{
                display: "grid",
                placeItems:
                  "center" /* Center both horizontally and vertically */,
              }}
            >
              <ChromePicker
                color={
                  designations[openConfirmationModelData] &&
                  designations[openConfirmationModelData].color
                }
                onChangeComplete={(newColor) =>
                  // setOptionValue(
                  //   newColor.hex,
                  //   "color",
                  //   openColorModelData.index,
                  //   openColorModelData.parentIndex
                  // )
                  setData("color", newColor.hex, openConfirmationModelData)
                }
                disableAlpha={true} // This will disable the opacity selector
              />
            </Box>
            <Button
              onClick={handleClose}
              variant="contained"
              color="secondary"
              size="small"
              sx={{ mt: 2, ml: "10px" }}
            >
              Close
            </Button>

            {hasAddedOptions(openConfirmationModelData) && (
              <Button
                onClick={()=>reapplyColor(false)}
                variant="contained"
                color="secondary"
                size="small"
                sx={{ mt: 2, ml: "10px" }}
              >
                Apply
              </Button>
            )}

             {hasAddedOptions(openConfirmationModelData) && !isMultipleUpload && (
              <Button
              onClick={()=>reapplyColor(true)}
                variant="contained"
                color="secondary"
                size="small"
                sx={{ mt: 2, ml: "10px" }}
              >
                Apply & Save
              </Button>
            )}
          </Box>
        </Box>
      </Modal>

      {/* Confirmation Dialog */}
      <Dialog
        open={openConfirmationModel}
        onClose={handleCloseDialog}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">
          Confirm Deletion
        </DialogTitle>
        <DialogContent>
          <Typography id="confirmation-dialog-description">
            Are you sure you want to delete this designation? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={deleteDesignation} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Full-page loader */}
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={pdfScriptLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};

export default MatterDeopsitionsPdf;
